var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items !== null)?_c('div',{staticClass:"card-list bg-gray-100"},[_c('DragDropFile',{attrs:{"disabledrop":_vm.disabledropitem,"column":_vm.column.item,"editor":_vm.editor,"authorization":_vm.authorization}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.groupBy !== 'none'),expression:"groupBy !== 'none'"}],staticClass:"font-weight-bold",class:_vm.column.item.title === 'others' ||
        !_vm.orderable ||
        !_vm.authorization.update ||
        _vm.groupBy !== 'heading'
          ? ''
          : 'headitem'},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"10"}},[_c('HeadingEditor',{staticClass:"card-list-header",attrs:{"view":_vm.view,"item":_vm.column.item,"properties":_vm.properties,"authorization":_vm.authorization}})],1)],1)],1)]),_c('BoardHeadingItemAdd',{directives:[{name:"show",rawName:"v-show",value:(_vm.groupBy !== 'none'),expression:"groupBy !== 'none'"}],staticClass:"addheadingboard",attrs:{"view":_vm.subview,"columnId":_vm.column.item._id,"columnType":_vm.groupBy}}),_c('draggable-virtual-list',_vm._b({staticClass:"card-list-body list-group u-fancy-scrollbar",attrs:{"disabled":!_vm.orderable || !_vm.authorization.update,"extra-props":{
      editor: _vm.editor,
      disabledrop: _vm.disabledropitem,
      perspective: _vm.perspective,
      allowDrag: _vm.orderable && _vm.authorization.update,
      collectionID: _vm.collectionID,
      account: _vm.account,
      authorization: _vm.authorization,
      properties: _vm.properties,
      collection: _vm.collection,
      column:_vm.column
    },"group":"itemgroup","animation":200,"delay":100,"delayOnTouchOnly":true,"touchStartThreshold":1,"size":50,"keeps":10,"handle":".dragicon","ghost-class":"ghost","data-key":'_id',"data-sources":_vm.items,"data-component":_vm.itemComponent},on:{"start":_vm.dragStart,"end":_vm.dragEnd,"newevent":function($event){return _vm.orderChange($event, _vm.column.item._id, _vm.subview)}},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},'draggable-virtual-list',_vm.dragOptions,false))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }