<template>
  <div class="card-list bg-gray-100" v-if="items !== null">
    <DragDropFile
      :disabledrop="disabledropitem"
      :column="column.item"
      class
      :editor="editor"
      :authorization="authorization"
    >
      <p
        class="font-weight-bold"
        :class="
          column.item.title === 'others' ||
          !orderable ||
          !authorization.update ||
          groupBy !== 'heading'
            ? ''
            : 'headitem'
        "
        v-show="groupBy !== 'none'"
      >
        <v-row no-gutters>
          <v-col cols="10" class="pa-0 ma-0">
            <HeadingEditor
              class="card-list-header"
              :view="view"
              :item="column.item"
              :properties="properties"
              :authorization="authorization"
            />
          </v-col>
        </v-row>
      </p>
    </DragDropFile>
    <BoardHeadingItemAdd
      v-show="groupBy !== 'none'"
      :view="subview"
      :columnId="column.item._id"
      :columnType="groupBy"
      class="addheadingboard"
    />
    <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
    <draggable-virtual-list
      :disabled="!orderable || !authorization.update"
      :extra-props="{
        editor: editor,
        disabledrop: disabledropitem,
        perspective: perspective,
        allowDrag: orderable && authorization.update,
        collectionID: collectionID,
        account: account,
        authorization: authorization,
        properties: properties,
        collection: collection,
        column:column
      }"
      group="itemgroup"
      :animation="200"
      :delay="100"
      :delayOnTouchOnly="true"
      :touchStartThreshold="1"
      class="card-list-body list-group u-fancy-scrollbar"
      :size="50"
      :keeps="10"
      handle=".dragicon"
      ghost-class="ghost"
      @start="dragStart"
      @end="dragEnd"
      @newevent="orderChange($event, column.item._id, subview)"
      v-model="items"
      :data-key="'_id'"
      :data-sources="items"
      :data-component="itemComponent"
      v-bind="dragOptions"
    ></draggable-virtual-list>
  </div>
</template>
<script>
import { View } from '@/collections'
import DraggableVirtualList from '@/components/scroll'
import BoardHeadingItemAdd from '@/components/layouts/BoardHeadingItemAdd.vue'
import BoardCard from '@/components/layouts/BoardCard.vue'
export default {
  name: 'BoardColumn',
  components: {
    DraggableVirtualList,
    DragDropFile: () => import('@/components/fileagent/DragDropFile.vue'),
    BoardHeadingItemAdd,
    HeadingEditor: () => import('@/components/HeadingEditor.vue')
  },
  data: function () {
    return {
      disabledropitem: false,
      subview: null,
      itemComponent: BoardCard
    }
  },
  props: {
    headingIds: { type: Array },
    editor: { type: Object },
    disabledrop: {
      type: Boolean,
      default: false
    },
    orderable: {
      type: Boolean,
      default: false
    },
    column: Object,
    authorization: Object,
    collectionID: String,
    account: String,
    view: Object,
    collection: Object,
    perspective: String,
    properties: Object,
    groupBy: {
      type: String,
      default: 'none'
    }
  },
  async created () {
    this.disabledropitem = this.disabledrop
    if (this.groupBy === 'heading') {
      if (this.column.item._id === 'others') {
        this.subview = await new View(this.collection).open(
          { ...this.view.vs, ...{ orderable: this.perspective + '_others' } },
          {
            $or: [
              { heading: { $exists: false } },
              { heading: { $nin: this.arrayOfHeadingIDs } }
            ],
            type: { $ne: 'heading' },
            $not: { completed: true }
          }
        )
      } else {
        this.subview = await new View(this.collection).open(
          { ...this.view.vs, ...{ orderable: this.column.item._id } },
          {
            heading: this.column.item._id
          }
        )
      }
      console.log('Subview==', this.column.item._id, this.subview)
    } else if (this.groupBy === 'assignee') {
      if (this.column.item._id === 'others') {
        this.subview = await new View(this.collection).open(
          {
            ...this.view.vs,
            ...{ orderable: this.perspective + '_assignee_others' }
          },
          {
            $and: [{ assignee: { $exists: false } }]
          }
        )
      } else {
        this.subview = await new View(this.collection).open(
          { ...this.view.vs, ...{ orderable: this.column.item.title } },
          {
            assignee: this.column.item.title
          }
        )
      }
    } else if (this.groupBy === 'status') {
      if (this.column.item._id === 'others') {
        this.subview = await new View(this.collection).open(
          {
            ...this.view.vs,
            ...{ orderable: this.perspective + '_status_others' }
          },
          {
            $and: [{ status: { $exists: false } }]
          }
        )
      } else {
        this.subview = await new View(this.collection).open(
          { ...this.view.vs, ...{ orderable: this.column.item.title } },
          {
            status: this.column.item.title
          }
        )
      }
    }
  },
  computed: {
    arrayOfHeadingIDs () {
      return this.headingIds.map(a => a.item._id)
    },
    dragOptions () {
      return {
        scroll: true,
        scrollSensitivity: 100,
        scrollSpeed: 10
      }
    },
    items: {
      // getter
      get: function () {
        if (this.subview !== null) {
          console.log(
            'sub view items render',
            this.column.item._id,
            this.subview.items
          )
          return this.subview.items
        }
        return null
      },
      // setter
      set: function (newValue) {
        this.subview.items = newValue
      }
    }
  },
  methods: {
    /**
     * call when drag event completed
     */
    async orderChange (event, column, view) {
      if (typeof event.instruction.moved !== 'undefined') {
        var element = event.item
        var newIndex = event.start
        console.log('set order item', element._id, newIndex)
        this.subview.setOrder(element._id, newIndex)
      }
      if (typeof event.instruction.added !== 'undefined') {
        var element = event.item
        var newIndex = event.start
        console.log('set order item', element._id, newIndex)
        if (this.groupBy == 'status') {
          if (column === 'others') {
            delete element.status
          } else {
            element.status = column
          }
        }
        if (this.groupBy == 'assignee') {
          if (column === 'others') {
            delete element.assignee
          } else {
            element.assignee = column
          }
        }
        if (this.groupBy == 'heading') {
          if (column === 'others') {
            delete element.heading
          } else {
            element.heading = column
          }
        }
        await this.subview.put(element)
        await this.subview.setOrder(element._id, newIndex)
      }
    },
    dragStart (e) {
      this.disabledropitem = true
      this.$emit('update:disabledrop', this.disabledropitem)
    },
    dragEnd (e) {
      this.disabledropitem = false
      this.$emit('update:disabledrop', this.disabledropitem)
    }
  },
  watch: {
    disabledrop: {
      handler: function (newVal, oldVal) {
        if (newVal != null) {
          this.disabledropitem = newVal
        }
      }
    }
  }
}
</script>
<style  scoped>
.card-list {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 0.75rem;
  border-radius: 0.5rem;
  padding: 1rem 0.5rem;
  background: #dee2e6;
}
.card-list-body {
  overflow-y: auto;
  min-height: 1rem;
}
.card-list-header {
  display: block;
  position: relative;
  padding-left: 5px;
}
</style>
