<template>
  <div>
    <v-btn
      x-small
      fab
      color="primary"
      title="Add new item"
      @click="addNewItem"
      class="addbutton"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <div v-show="itemSection">
      <div class="pt-0">
        <v-textarea
          ref="itemTitle"
          solo
          flat
          placeholder="Enter title here..."
          v-model="itemTitle"
          hide-details="false"
          autofocus
          height="80"
        />
        <v-btn small color="primary" class="my-2 ml-2" @click="saveItem">
          <v-icon class="mr-1">mdi-check</v-icon>Add
        </v-btn>
        <v-btn icon small fab class="my-2 ml-2" @click="cancelItem">
          <v-icon class="mr-1">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import Utility from '@/components/common/utils.js'
export default {
  name: 'BoardHeadingItemAdd',
  components: {},
  data: function () {
    return {
      itemSection: false,
      itemTitle: '',
      utils: Utility
    }
  },
  props: {
    view: Object,
    columnType: String,
    columnId: String
  },
  computed: {},
  methods: {
    addNewItem () {
      this.itemSection = true
    },
    async saveItem () {
      if (this.itemTitle === '') return
      var item = {
        title: this.itemTitle,
        _id: this.utils.uuidv4()
      }
      item[this.columnType] = this.columnId
      if (this.columnId === 'others') {
        delete item[this.columnType]
      }
      console.log('Add new item in ' + this.columnType, item)
      await this.view.put(item)
      await this.view.setOrder(item._id, 0)
      this.itemSection = false
      this.itemTitle = ''
    },
    cancelItem () {
      this.itemSection = false
      this.itemTitle = ''
    }
  }
}
</script>
<style scoped>
</style>
